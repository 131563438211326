@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&family=Rubik:wght@300;500;600;700&family=Source+Sans+Pro:wght@200;300;600&family=Source+Serif+Pro:wght@200;400;600&family=Teko&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=REM:wght@100;400;600;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');

button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  color: #faf9f1;
}
.HomePage-M-T {
  width: -webkit-fill-available;
  height: 100px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(30px + 3vw);
  z-index: 2;
}
.HomePage-M-T-F {
  width: -webkit-fill-available;
  height: 75px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(30px + 3vw);
  bottom: 0;
  justify-content: center;
}
.HomePage-M-T-H {
  width: -webkit-fill-available;
  height: 50px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(30px + 3vw);
  opacity: 0.9;
  z-index: 100;
  top: 0;
  /* left: 5vw; */
}
.HomePage-M-T-R {
  height: -webkit-fit-content;
  height: fit-content;
  height: -moz-fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  text-decoration: none;
  z-index: 100;
  position: absolute;
  top: 32px;
  right: 5vw;
}
.HomePage-M-T-L {
  height: -webkit-fit-content;
  height: fit-content;
  height: -moz-fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  text-decoration: none;
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 5vw;
}
.HomePage-M-T-L svg {
  color: #d49d81;
  font-size: 16px;
  margin-right: 7px;
  transform: scale(-1, 1);
}
#Layer_1 {
  transform: scale(1, 1);
}
.HomePage-M-T-L a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  z-index: 2;
  color: #fff;
  font-family: Rubik, sans-serif;
  font-size: 13px;
  font-weight: 300;
}
.HomePage-M-T-L p {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.HomePage-M-T-L b {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
}
.HomePage-M {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  /* top: 20px;
  bottom: 80px; */
  /* left: 30px;
  right: 10px; */
  max-width: 1600px;
  width: calc(100vw - 150px);
  height: calc(100vh - 150px);
  padding-bottom: 10px;
  margin: auto 0;
}
.MenuIcon-T {
  width: 30px;
  height: 2px;
  border-radius: 15px;
  background-color: #ffffff;
  margin: 5px;
}
.MenuIcon-B {
  width: 15px;
  height: 2px;
  border-radius: 15px;
  background-color: #ffffff;
  margin: 5px;
}
.MenuInsideN {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000c0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  border-radius: 30px;
  border: 1px solid #00000056;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.ContactInfo {
  position: relative;
  font-weight: 700;
  color: #faf9f1;
  font-size: 30px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  opacity: 0.8;
  margin-top: 0;
  margin: 10vh 10vw 0 10vw;
  display: flex;
}
.ContactInfos {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 15vh 10vw;
}
.ContactInfo-In {
  flex-direction: column;
  overflow: hidden;
  display: flex;
  text-decoration: none;
}
.ContactCategory {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 20px;
  color: #d49d81;
  opacity: 1;
  font-weight: 600;
  font-size: 15px;
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin: 1.5vh 1vh 0.5vh 1vh;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}
.ContactInfo-In b {
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  color: #fff;
  opacity: 1;
  font-family: "Rubik", sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin: 1vh 1vh;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: transform 0.2s ease, opacity 0.5s ease;
  margin-top: 0;
  display: flex;
  margin-bottom: 4px;
}
.ContactInfo-In p2 span {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  color: #fff;
  opacity: 1;
  font-family: "Rubik", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: transform 0.2s ease, opacity 0.5s ease;
  margin-top: 0;
}
.HomeAround {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  position: fixed;
  bottom: 20px;
  top: 20px;
  height: calc(100vh - 40px);
  width: calc(100vw - 20px);
  margin: 0;
  left: 10px;
  right: 10px;
}
.MainHeader {
  position: fixed;
  top: 20px;
  left: calc(50px + 3vw);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.resumee {
  display: flex;
  align-items: center;
  flex: 20%;
  justify-content: center;
}
.HomeConsole {
  position: fixed;
  bottom: 20px;
  height: 50px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #684b3d34;
  border: 1px solid #684b3d7f;
  border-radius: 100px;
  flex-direction: row;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.FooterOptions {
  position: absolute;
  bottom: 80px;
  width: 5px;
  /* right: calc(5vw + 0px); */
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 100px;
  flex-direction: row;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.animated-option-wrapper:nth-of-type(1) {
  font: inherit;
  color: #d49d81e0;
  position: relative;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  font-size: 11.5px;
  opacity: 0;
  -moz-text-align-last: left;
  text-align-last: left;
  margin-left: 1vw;
  margin-bottom: -5px;
  margin-left: 25px;
  z-index: 1;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #000000;
  line-height: 5px;
}
.animated-option-wrapper:nth-of-type(n + 2) {
  position: relative;
  width: 15vw;
  height: 50px;
  background-color: #00000060;
  border-radius: 100px;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none !important;
  color: #faf9f1;
  opacity: 0;
  font-weight: 500;
  overflow: hidden;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  margin-top: -15px;
  border: 3px solid #d49d8100;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  min-width: 200px;
}
.RotatingSphere {
  opacity: 0;
  position: fixed;
  bottom: -50px;
  right: -50px;
  opacity: 0.7;
}
.PersonalOptions {
  margin-bottom: -20px;
}
.animated-option-wrapper:nth-of-type(n + 2):hover {
  transform: scale(0.98);
  cursor: pointer;
  border: 3px solid #d49d8146;
}
.contact-1 {
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  align-items: center;
  flex: 50%;
  justify-content: center;
}
.Social-Media {
  font: inherit;
  color: #d49d81;
  position: relative;
  font-family: Rubik, sans-serif;
  font-weight: 600;
  transition: all 0.2s linear;
  font-size: 11.5px;
  opacity: 0.7;
}
.DownloadSvg {
  margin-right: 10px;
  color: #d49d81;
  font-size: 17px;
  opacity: 0.85;
}
.social {
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  margin-left: 4vw;
  transition: all 0.2s linear;
}
.social a {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-right: 2.5vw;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  text-decoration: none !important;
  color: #faf9f1;
  opacity: 0.8;
  font-weight: 500;
  overflow: hidden;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
}
.social button {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-right: 2.5vw;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  text-decoration: none !important;
  color: #faf9f1;
  opacity: 0.8;
  font-weight: 500;
  overflow: hidden;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  background: none;
}
.b-hr {
  background: linear-gradient(to right, #f9ebe50f 0%, #d4a58d38 100%);
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  width: 2px;
  position: relative;
  height: 30px;
  margin: 0 3% 3px 3%;
  -webkit-transition: opacity 0.4s ease-in-out;
  opacity: 0.7;
  height: 20px;
  width: 500px;
  border-radius: 100px;
  margin-top: -10px;
  margin-left: -10px;
}
.b-hr-2 {
  background: linear-gradient(to right, #f9ebe528 0%, #d4a58dbe 100%);
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  width: 2px;
  position: relative;
  height: 30px;
  margin: 0 3% 3px 3%;
  -webkit-transition: opacity 0.4s ease-in-out;
  opacity: 0.7;
}
.HomePage-M-3 {
  display: flex;
  flex-direction: row;
  margin-bottom: 5vh;
}
.glassy-lines-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(20vw + 100px);
  opacity: 0.2;
}
.glassy-line {
  height: 100%;
  background: linear-gradient(
    to right,
    rgb(255 255 255 / 100%) 10%,
    rgba(255, 255, 255, 0) 90%
  );
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
  border-radius: 2.5px;
  margin-right: 5px;
}
.HomePage-M-3 p {
  display: inline-block;
  color: #faf9f1;
  position: relative;
  -webkit-animation-duration: 1200ms;
  animation-duration: 1200ms;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  text-align: left;
  font-weight: 400;
}
.HomePage-M-3 a {
  text-decoration: unset;
}
.HomePage-M-3 svg {
  fill: #d49d81;
  transform: scale(0.2);
  width: 100px;
  overflow: initial;
  height: 20px;
}
.HomePage-M b {
  position: relative;
  color: #faf9f1;
  font-family: "DM Serif Display", serif;
  font-size: 62px;
  line-height: 50px;
  font-weight: 400;
  white-space: nowrap;
  perspective: 400px;
  text-align: initial;
  margin-left: 40px;
  margin-bottom: 5vh;
  background: linear-gradient(to right, #faf5f3a2 0%, #ff5500e0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: -80px;
}
.welcome {
  color: #faf9f1;
  margin: 0;
  margin-left: 37px;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  -webkit-transition: all 1s ease-in-out !important;
  display: flex;
  position: relative;
  align-items: center;
  -webkit-animation-duration: 1400ms;
  animation-duration: 1400ms;
  margin-top: -60px;
}
.welcome span {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  position: relative;
  font-family: "DM Serif Display", serif;
  font-size: 150px;
  font-weight: 400;
  white-space: nowrap;
  perspective: 400px;
}
.fav {
  display: flex;
  position: relative;
  justify-content: space-around;
  margin-right: 3vw;
}
.pro {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.pro p:nth-child(1) {
  color: #d49d81;
  font-family: Rubik, sans-serif !important;
  font-size: 10px !important;
  line-height: 18px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  /* margin: 3px 0; */
  margin-bottom: 0;
}
.pro p:nth-child(2) {
  color: #faf9f1;
  font-family: "Teko", sans-serif;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
  perspective: 400px;
  text-transform: capitalize;
  margin: 0;
}
.DownloadSvg {
  margin-left: -19.5vw;
}
.HomePage-M-3 p span {
  line-height: 30px;
}
.HomePage-M-3 p div span {
  line-height: 15px;
}
.iconcross {
  position: absolute;
  border-radius: 15px;
  background-color: #d49d81;
  margin: 5px;
  width: 15px;
  height: 3px;
  bottom: 18px;
  right: 12px;
  cursor: pointer;
}
.icon-wrapper {
  cursor: pointer;
  position: absolute;
  right: 0vw;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background-color: #00000060;
  border-radius: 100px;
  flex-direction: row;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
}
@media only screen and (max-width: 1120px) {
  .MainHeader {
    width: 90vw;
    top: 5px;
  }
  .fav {
    margin-right: 0;
  }
  .welcome {
    justify-content: center;
  }
  .contact-1 {
    display: none;
  }
  .b-hr {
    display: none;
  }
  .resumee {
    justify-content: space-around;
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
    flex: none;
    position: absolute;
  }
  .social a {
    margin-right: 0;
  }
  .DownloadSvg {
    margin-left: -4vw;
  }

  .ContactInfos {
    flex-direction: column;
    margin: 10vh 10vw;
  }

  .HomePage-M-3 svg {
    display: none;
  }
  .HomePage-M-3 {
    margin-right: 0;
    margin-bottom: 3vh;
    align-items: center;
    justify-content: center;
  }
  .HomePage-M-3 p {
    text-align: -webkit-match-parent;
    text-align: match-parent;
  }
  .HomePage-M-3 p1 {
    text-align: center;
  }
  .fav {
    flex-direction: row;
    align-items: center;
  }
  .pro {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    margin-bottom: 0;
  }
  .HomePage-M b {
    align-self: center;
    margin-left: 0;
    margin-top: -122px;
  }
  .HomePage-M-R {
    display: none;
  }
  .welcome {
    margin: 0;
    justify-content: center;
    margin-top: -50px;
  }
  .ContactInfo-In {
    margin: 10px 0;
  }
  .MenuInsideN {
    position: absolute;
    bottom: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    margin: 0;
    left: 0px;
    right: 0px;
  }
  .HomePage-M-T-F {
    padding: 0 calc(3vw);
    justify-content: center;
  }
}
@media only screen and (max-width: 640px) {
  /* .HomePage-M-T-H {
    height: 60px;
    width: 40vw;
  } */
  /* .HomePage-M-T-H {
    padding: 0 calc(3vw);
    height: 70px;
  } */
  .resumee {
    width: 80% !important;
  }

  .HomePage-M-T-R {
    margin-right: 10px;
  }
  .MainHeader {
    position: relative;
    border-radius: 100px;
    margin-left: 10px;
  }
  .pro p:nth-child(1) {
    margin-bottom: 0;
  }

  .HomePage-M-3 p {
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    margin: 0 -10px;
    width: 100%;
  }
  .HomePage-M-T-B {
    margin-top: 0px;
    display: flex !important;
    align-items: center;
    padding: 3px 12px 5px;
    border-radius: 20px;
  }
  .welcome span {
    font-size: 100px;
    line-height: 150px;
  }
  .b-hr {
    display: flex;
    height: 30px;
    transform: translateY(3px);
    opacity: 0.9;
  }
  .HomePage-M {
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    margin: 0;
    margin-bottom: 70px;
  }
  .HomePage-M b {
    font-size: 40px;
    line-height: 30px;
    margin-top: -85px;
    margin-bottom: 6.5vh;
    background: linear-gradient(to right, #faf5f3a2 0%, #ff7a37e0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .HomeConsole {
    bottom: 10px;
  }
  .b-hr {
    display: none;
  }
  .welcome p {
    font-size: 60px;
    line-height: 60px;
  }
  .icon-wrapper {
    background-color: #ffffff09;
  }
  .animated-option-wrapper:nth-of-type(n + 2) {
    background-color: #000000d8;
  }
  .b-hr {
    height: 15px;
    width: 300px;
    border-radius: 100px;
    margin-top: -8px;
    margin-left: 12px;
  }
  .animated-option-wrapper:nth-of-type(n + 2) {
    width: 90vw;
    margin-bottom: 5px;
  }
  .FooterOptions {
    bottom: 70px;
  }
}
@media only screen and (max-height: 520px) {
  .HomePage-M-R {
    display: none;
  }
  .HomePage-M {
    width: 100vw;
  }

  .MainHeader {
    top: 10px;
  }
}
@media only screen and (max-height: 390px) {
  .HomePage-M b {
    display: none;
  }
}
@media only screen and (max-height: 550px) and (max-width: 1120px) {
  .HomePage-M b {
    display: none;
  }
  .fav {
    flex-direction: row;
  }
}
